<template>
	<div class="app-body">
		<div class="home-container scroll-cont">
			<div class="home-amount-cont" >
				<div class="back-cont">
					<div class="back-search f14">
						<span class="input-box">
							<i class="search-scan" @click="scanSearch"><img src="../../assets/images/scan.png" alt="" style="width:0.6rem;"></i>
							<input type="text" v-model="searchInfo.imei" placeholder="请输入机柜编号查找机柜归属">
							<i class="search-icon" @click="filterKey"><img src="../../assets/images/search_blue.png" alt="" style="width:0.6rem;"></i>
						</span>
					</div>
					<div class="back-search f14">
						<span class="select-box">
							<select name="" id="" class="f14" v-model="searchInfo.status" @change="onRefresh">
								<option value="">全部报修单</option>
								<option :value="index" v-for="(item,index) in statusInfo" v-bind:key="index">{{statusInfo[index]['name']}}</option>
							</select>
						</span>
					</div>
					<div class="back-cont">
						<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
							<van-list v-model="loading" :finished="finished"  @load="onLoard">
								<div class="back-list f14" v-for="item in list" :key="item.id" @click="godetail({path:'/RepairDetail',query:{repair_id:item.id}})">
									<div class="back-list-top" >
										<p>报修单号：{{item.repair_num}} 
											<span class="right f16" :style="'color:'+statusInfo[item.state]['color']">{{item.repair_status}}</span>
										</p>
										<div>
											<table style="width:80%">
												<tr>
													<th width="100">设备名称</th>
													<th width="100">数量</th>
												</tr>
												<tr v-for="(goods,index) in item.cabinets" v-bind:key="index">
													<td>{{goods.cabinet_type}}</td>
													<td>{{goods.amount}}台</td>
												</tr>
											</table>
										</div>
										<p class="text-grey mts">充电宝总数：{{item.battery_total}}</p>
										<p class="text-grey mts">提交人：{{item.submitter}}</p>
										<p class="text-grey mts">提交时间：{{item.create_time}}</p>
									</div>
									<div class="back-list-bottom clear">
										<span class="right">
											<button class="btn btn-blue btn-sm" v-if="isExist(['2'],item.state)" @click.stop="godetail({path:'/RepairDetail',query:{repair_id:item.id}})">去审核</button>
											<button class="btn btn-blue btn-sm" v-if="isExist(['1','5'],item.state)" @click.stop="goLog(item.logistics_num)">查看物流</button> 
											<button class="btn btn-blue btn-sm" v-if="isExist(['4','5','7'],item.state)" @click.stop="godetail({path:'/RepairDetail',query:{repair_id:item.id}})">去核销</button> 
										</span>
									</div>
								</div>
							</van-list>
						</van-pull-refresh>
					</div>
					<div class="nodata" v-if="list.count == 0">
						<p><img src="../../assets/images/nodata.png" alt="" class="noImg"></p>
						<p class="f14 text-grey">暂无数据</p>
					</div>
				</div>
			</div>
		</div>
		<van-popup v-model="showDialog" :style="{ width: '90%' , 'border-radius': '8px'}">
			<div class="vanPopup-box" >
				<p class="f18 title text-color"><strong>{{tableSingle.state_name}}</strong></p>
				<div>
					<p class="mt f14">设备编号：{{tableSingle.imei}}</p>
					<p class="mt f14">报修单号：{{tableSingle.repair_num}}</p>
					<p class="mt f14">报修时间：{{tableSingle.repair_time}}</p>
					<p class="mt f14">提交人：{{tableSingle.operator_name}}</p>
				</div>
				<div class="vanPopup-bottom">
					<span><button class="btn btn-block btn-md f16 btn-blue" @click="showDialog = false">确定</button></span>
				</div>
			</div>
		</van-popup>
	</div>
</template>
<script>
import wx from 'weixin-js-sdk'
import { wxbase } from "@/api/user.js"
import { Toast,PullRefresh,List,Popup  } from 'vant'
import { repairReportList  , repairCabinetSearch } from '@/api/device'
export default{
	components: {
		[PullRefresh.name]:PullRefresh,
		[List.name]:List,
		[Popup.name]:Popup,
	},
	data() {
		return {
			list: [],
			isLoading: false,   
			loading: false,   
			finished: false,
			currentPage : 0,
			searchInfo:{
				'status':'',
				'imei':''
			},
			statusInfo:{
				'2':{'name':'待审核','color':'#ff5252'},
				'4':{'name':'待发货','color':'#35b0fa'},
				'5':{'name':'待核销','color':'#35b0fa'},
				'3':{'name':'未通过','color':'#ff5252'},
				'1':{'name':'已完成','color':'#0ad162'},
				'6':{'name':'已撤销','color':'#ffcc00'},
				'7':{'name':'审核中','color':'#209ef7'}
			},
			tableSingle: {},
			showDialog: false
		}
	},
	watch: {

	},
	computed: {

    },
	mounted() {
	
	},
	methods: {
		filterKey() {
			if(this.searchInfo.imei == ''){
				Toast('机柜二维码不能为空')
			}else{
				let params = {}
				params.imei = this.searchInfo.imei
				repairCabinetSearch(params).then(res => {
					console.log(res)
					if(res.data.error_code == 1000) {
						this.tableSingle = res.data.data
						this.showDialog = true
					}
				}).catch(error=>{
					console.log(error)
				})
			}
        },
        scanSearch() {
			var self = this
			let url = window.location.href.split('#')[0]
			wxbase(url).then(response => {
				var jsondata = response.data
				wx.config({
					debug: false,
					appId: jsondata.appid,
					timestamp: jsondata.timestamp,
					nonceStr: jsondata.nonceStr,
					signature: jsondata.signature,
					jsApiList: [
						'checkJsApi',
						'scanQRCode'
					]
					// 需要使用的JS接口列表
				});
				wx.ready(function () {
					wx.checkJsApi({
						jsApiList: ['scanQRCode'],
						success: function () {
						}
					});
					wx.scanQRCode({
						needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
						scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
						success: function (res) {
							var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
							if(result.split('=')[1]){
								self.searchInfo.imei = result.split('=')[1]
								self.filterKey()
							}else{
								Toast('当前不是合格的机柜二维码')
							}

							console.log("扫描结果："+result);
						}
					});
				}); 
				wx.error(function (res) {
					console.log("出错了：" + res.errMsg);
				});
			}).catch(error=>{
				console.log(error);
			});
        },
		isExist(arr,param) {
			if(arr.indexOf(String(param)) > -1) return true
			else return false
		},
		godetail(path) {
			this.$router.push(path)
		},
		onLoad() {
			let params = {}
			params.page = this.currentPage
			params.state = this.searchInfo.status
			this.loading = true
			//分页数据
			repairReportList(params).then(res => {
				if(res.data.error_code != 1000)
				{
						this.isLoading = false
						this.finished = true
						this.loading = false
						this.list.length = 0
						this.list.count = 0
				}else{
					if(res.data.data.data.total > 0) {
						
						if(this.loading)
						{
							this.list = this.list.concat(res.data.data.data.data)
							this.loading = false
						}
						if(this.isLoading)
						{
							this.isLoading = false
							this.list = res.data.data.data.data
						}
						if(this.list.length >= res.data.data.data.total){ 
							this.finished = true
						}else{
							this.finished = false
						}
					}else{
						this.isLoading = false
						this.finished = true
						this.loading = false
						this.list.length = 0
						this.list.count = 0
					}
				}
			}).catch(error=>{
				console.log(error)
			})
		},
		onRefresh() { 
			//下拉刷新
			this.isLoading = true
			this.currentPage = 1
			this.onLoad()
		},
		onLoard() {
			//上拉加载
			this.currentPage++
			this.loading = true
			this.onLoad()
		},
		goLog(name) {
			window.location.href = 'https://m.kuaidi100.com/result.jsp?nu='+name
		}
	}
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/mixin';
.back-nav{
	display:flex;
	margin:0.6rem 0 0.4rem;
	div{
		flex:1;
		text-align:center;
		&.active{
			color:#35b0fa;
			span{
				border-bottom:3px solid #35b0fa;
				padding:0.2rem 0;
				color:#35b0fa;
				font-weight:500;
				display:block;
				width:20%;
				margin:0 auto;
			}
		}
	}
}
.back-search{
	margin:0.4rem 0.4rem 0;
	background:#fff;
	border-radius:5px;
	display:flex;
	padding:0.3rem 0;
	align-items:center;
	box-shadow:0px 1px 1px #eeeeee;
	.select-box{
		padding:0 0.4rem;
		flex:1;
		select{
			-webkit-appearance:none;
			apperance:none;
			background:url('../../assets/images/arrow-down.png') no-repeat right center;
			background-size:0.25rem;
			padding-right:0.4rem;
			width:100%;
		}
	}
	.input-box{
		flex:1;
		background:none;
		padding:0 0.2rem;
		position:relative;
		input{
			width:100%;
			padding-left:1.0rem;
		}
		.search-scan{
			position:absolute;
			left:0.4rem;
			top:-0.1rem;
		}
		.search-icon{
			position:absolute;
			right:0.4rem;
			top:-0.1rem;
		}
	}
}
.back-title{
	margin:0.4rem 0.4rem 0;
}
.back-list{
	margin:0.4rem;
	background:#fff;
	border-radius:5px;
	padding:0 0.4rem;
	box-shadow:0px 1px 1px #eeeeee;
	.back-list-top{
		padding:0.4rem 0;
	}
	.back-list-bottom{
		padding:0.4rem 0;
		border-top:1px solid #f5f5f5;
	}
	table{
		width:100%;
		text-align:center;
		padding:0;
		margin:0.4rem 0;
		border-collapse: collapse;
		border-spacing:0;
		tr > th{
			color:#999999;
		}
		tr > td , tr > th{
			border:1px solid #dbdbdb;
			padding:10px 0;
		}
	}
}
</style>